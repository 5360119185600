<div class="flex-column" flexGap="4px">
  @if (label()) {
    <mat-label class="labels-label-s greyscale-light-text">{{ label() }}</mat-label>
  }

  <div class="relative" matAutocompleteOrigin #origin="matAutocompleteOrigin">
    <div class="search-input" [class.disabled]="searchControl.disabled">
      @if ((auto.isOpen && !searchControl.value) || (!auto.isOpen && !value() && !searchControl.value)) {
        {{ placeholder() }}
      }

      @if (!inFocus()) {
        @if (selectedOption(); as selectedOption) {
          <div class="flex-row" flexGap="8px" flexLayoutAlign="start center">
            @if (selectedOption.name) {
              <ngx-q360-avatar
                [avatar]="{ photoUrl: selectedOption.photoUrl, name: selectedOption.name }"
                [sasUrl]="sasUrl()"
              />
            }
            <div class="flex-column">
              @if (selectedOption.name) {
                <div class="labels-label-l greyscale-darkest-grey">{{ selectedOption.name }}</div>
              }
              @if (displayEmail()) {
                <div
                  [class.labels-label-s]="selectedOption.name"
                  [class.greyscale-light-text]="selectedOption.name"
                  [class.labels-label-l]="!selectedOption.name"
                  [class.greyscale-darkest-grey]="!selectedOption.name"
                >
                  {{ selectedOption.email }}
                </div>
              }
            </div>
          </div>
        }
      }
    </div>
    <input
      #searchInput
      class="search-input-input"
      [class.transparent-color]="!inFocus()"
      matInput
      [formControl]="searchControl"
      [matAutocomplete]="auto"
      [matAutocompleteConnectedTo]="origin"
      (focus)="onFocus()"
      (blur)="onBlur()"
    />
  </div>
</div>

<mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)" (opened)="onOpened()">
  @if (loading()) {
    <mat-option class="text-align-center" [disabled]="true">Loading...</mat-option>
  } @else {
    @for (option of options(); track option.id) {
      <mat-option [value]="option.id" (click)="onOptionClick()">
        <div class="flex-row" flexGap="8px" flexLayoutAlign="start center">
          <ngx-q360-avatar [avatar]="{ photoUrl: option.photoUrl, name: option.name }" [sasUrl]="sasUrl()" />
          <div class="flex-column">
            <div
              class="labels-label-l greyscale-darkest-grey"
              [innerHTML]="option.name | searchMarkerUnderline: emailLocalPart"
            ></div>
            @if (displayEmail()) {
              <div
                class="labels-label-s greyscale-light-text"
                [innerHTML]="option.email | searchMarkerUnderline: emailLocalPart | searchMarkerUnderline: emailDomain"
              ></div>
            }
          </div>
        </div>
      </mat-option>
    } @empty {
      @if (searchControl.value && (searchControl.value.includes('@') || !displayEmail()) && !options().length) {
        <mat-option class="text-align-center" [disabled]="true">No users found.</mat-option>
      }

      @if (searchControl.value?.length > 2 && enableNewItem()) {
        <mat-option [value]="this.searchControl.value">
          <div
            class="select-item primary-colors-accent-blue labels-label-l"
            fxLayout="row"
            fxLayoutAlign="start center"
          >
            <svg class="mr-8 svg-24" svgIcon="basic-plus" />
            Create new {{ itemName() }} "{{ this.searchControl.value }}"
          </div>
        </mat-option>
      }
    }
  }
</mat-autocomplete>
